<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Hệ đào tạo:"
                      label-for="trainingSystemSelected"
                    >
                      <v-select
                        v-model="trainingSystemSelected"
                        :options="trainingSystems"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        placeholder="1.Hệ đào tạo"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Khóa - Học kỳ:"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        :options="coursesSemestersOptions"
                        :reduce="(option) => option.value"
                        :clearable="false"
                        placeholder="2.Chọn khóa - học kỳ"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <validation-observer
              ref="studentExaminationPointFormRef"
              #default="{invalid}"
            >
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-show="coursesSemestersSelected !== null"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      style="margin-right: 5px"
                      @click="onSelectStudentGraduationProject"
                    >
                      <span class="text-nowrap text-right">Danh sách</span>
                    </b-button>
                    <b-button
                      v-show="studentGraduationProjects.length > 0 && isAllowRegister"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="invalid"
                      @click="onSave(studentGraduationProjects)"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="studentGraduationProjects"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    class="my-table"
                    style-class="my-table vgt-table striped bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'score'">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|between:0,10"
                          name="Điểm"
                        >
                          <b-form-input
                            :disabled="props.row.locked === 1"
                            v-model="props.row.score"
                            :tabindex="props.row.originalIndex + 1"
                            @change="
                              changeCell(
                                props.row.score,
                                props.column.field,
                                props.row.originalIndex
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else-if="props.column.field === 'note'">
                        <b-form-input
                          :disabled="props.row.locked === 1"
                          v-model="props.row.note"
                          type="text"
                          @change="
                            changeCell(
                              props.row.note,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </span>
                      <!-- Column: Common -->
                      <span v-else>{{
                        props.formattedRow[props.column.field]
                      }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import moment from 'moment'

export default {
  name: 'StudentExaminationPoint',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
    BFormInput,
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      coursesSemestersSelected: undefined,
      isLoading: false,
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Học viên',
          field: 'studentName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Đề tài',
          field: 'graduateDissertationName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'teacherName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm bằng số',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      required,
      between,
      courseSemesterCurrent: [],
      isAllowRegister: false,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      studentGraduationProjects: 'graduationProjectScore/studentGraduationProjects',
      coursesSemesters: 'studentExaminationRoom/coursesSemesters',
      graduationProjectRegisterRounds: 'graduationProjectScore/graduationProjectRegisterRounds',
    }),
    coursesSemestersOptions() {
      return this.coursesSemesters.map(item => ({
        value: item.course_id,
        label: item.name,
      }))
    },
    examinationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.GRADUATION_PROJECT_SCORE)
    },
  },
  watch: {
    async trainingSystemSelected(val) {
      this.coursesSemestersSelected = null
      if (!val) {
        this.reset()
        this.$store.commit('studentExaminationRoom/SET_COURSESSEMESTERS', {
          data: [],
        })
      } else {
        await this.getCoursesSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelected,
        })
      }
    },
    async coursesSemestersSelected(val) {
      if (!val) {
        this.reset()
      } else {
        this.$refs.studentExaminationPointFormRef.reset()
        await this.getStudentGraduationProjects({
          trainingSystemId: this.trainingSystemSelected,
          courseId: this.coursesSemestersSelected,
        })
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await Promise.all([
        this.getGraduationProjectRegisterRounds({
          status: 1,
          currentPage: 1,
          itemsPerPage: 10,
        }),
        this.getTrainingSystems({ organizationId: getUser().orgId }),
      ])
      if (this.graduationProjectRegisterRounds.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.courseSemesterCurrent = this.graduationProjectRegisterRounds[0]
      }
      const openDate = +moment(this.courseSemesterCurrent.startDate, 'DD/MM/YYYY HH:mm')
      const closeDate = +moment(this.courseSemesterCurrent.endDate, 'DD/MM/YYYY HH:mm')
      const intTimeCurren = +moment()
      if (openDate <= intTimeCurren && closeDate >= intTimeCurren) {
        this.isAllowRegister = true
      }
      if (this.trainingSystemId) {
        this.trainingSystemSelected = this.trainingSystemId
      }
      this.coursesSemestersSelected = null
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getStudentGraduationProjects:
        'graduationProjectScore/getStudentGraduationProjects',
      getCoursesSemesters: 'studentExaminationRoom/getCoursesSemesters',
      saveScores: 'graduationProjectScore/saveScores',
      getGraduationProjectRegisterRounds: 'graduationProjectScore/getGraduationProjectRegisterRounds',
    }),
    reset() {
      this.$store.commit(
        'graduationProjectScore/SET_STUDENT_GRADUATION_PROJECTS',
        { data: [] },
      )
    },
    async onSelectStudentGraduationProject() {
      this.isLoading = true
      try {
        await this.getStudentGraduationProjects({
          trainingSystemId: this.trainingSystemSelected,
          courseId: this.coursesSemestersSelected,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    changeCell(data, column, row) {
      this.studentGraduationProjects[row][column] = data
    },
    async onSave(data) {
      if (!this.examinationPointUpdatable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập điểm thi!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score === null || element.score === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      // eslint-disable-next-line no-restricted-globals
      if (data.some(element => isNaN(element.score))) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đúng kiểu dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score < 0 || element.score > 10)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 10!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const scores = data.map(element => ({
        id: element.id,
        score: element.score,
        note: element.note || null,
      }))
      try {
        const response = await this.saveScores({
          scores,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await Promise.all([
              this.getStudentGraduationProjects({
                trainingSystemId: this.trainingSystemSelected,
                courseId: this.coursesSemestersSelected,
              }),
            ])
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
